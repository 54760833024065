import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import PageHeader from "../components/PageHeader"
import { useNavigate } from "@reach/router"

interface NotFoundPageTemplateProps {
  html: string
}

const NotFoundPageTemplate: FC<NotFoundPageTemplateProps> = ({
  html
}) => {
  const navigate = useNavigate()
  return (
    <main
      id="not-found-page"
      className="px-6 py-32"
      style={{
        minHeight: "66vh"
      }}
    >
      <div className="mx-auto max-w-xl">
        <button
          onClick={() => {
            navigate(-1)
          }}
        >
          &larr; Gå tilbake
        </button>

        <div
          className="my-20 markdown"
          dangerouslySetInnerHTML={{
            __html: marked(html)
          }}
        />
      </div>
    </main>
  )
}

interface NotFoundPageProps {
  data: {
    markdownRemark: {
      html: string
    }
  }
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const { html } = data.markdownRemark
  return (
    <>
      <PageHeader />

      <NotFoundPageTemplate
        html={html}
      />
    </>
  )
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query NotFoundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
